<template>
  <custom-popup
      class="new-check-point"
      :show="show"
      title="新增自定义检查点"
      @on-close="$emit('on-close')"
  >
    <div class="content">
      <field-item label="检查项名称" required>
        <el-input v-model="form.checkName" placeholder="请输入" size="small" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查点名称" required>
        <el-input v-model="form.checkPointName" placeholder="请输入" size="small" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查点文字描述" required>
        <el-input v-model="form.checkPointDecription" placeholder="请输入检查点文字描述" type="textarea" :rows="3" @blur="handleInputBlur"/>
      </field-item>
      <field-item label="检查项辅助文件">
        <div class="uploader-file-img-box">
          <div class="upload-tip">支持 JPG、GIF、PNG、PDF、DOC、EXCEL 格式文件</div>
          <div class="file-box">
            <div v-if="form.fileList.length">
              <div v-for="(item, index) in form.fileList" :key="index" class="item space-between">
                <div class="file-name">
                  <img v-if="item.status === 'loading'" src="~@/assets/svg/icon-upload-loading.svg" alt="">
                  <img v-else-if="item.status === 'success'" src="~@/assets/svg/icon-upload-check.svg" alt="">
                  <div class="name">{{ item.file.name }}</div>
                </div>
                <div class="del-btn center" @click="handleFileDel(index)">删除</div>
              </div>
            </div>
            <van-uploader
              :after-read="handleReadFile"
              accept=".pdf,.doc,.xlsx,.xls"
              :preview-size="69"
              :upload-icon="require('@/assets/svg/icon-upload-add.svg')"
            >
              <van-button color="#055C9D" plain class="upload-btn">上传文件</van-button>
            </van-uploader>
          </div>
        </div>
      </field-item>
      <field-item label="">
        <van-uploader
            v-model="form.imgList"
            :after-read="handleReadImg"
            :preview-size="70"
            :upload-icon="require('@/assets/svg/icon-upload-add.svg')"
            class="image-uploader"
        >
          <van-button color="#055C9D" plain class="upload-btn">上传图片</van-button>
        </van-uploader>
      </field-item>
      <field-item label="是否有拍照需求">
        <van-switch v-model="form.havePhotograghRequire" active-color="#055C9D"/>
      </field-item>
      <field-item label="拍照数量" :required='form.havePhotograghRequire'>
        <el-select v-model="form.photoNumber" placeholder="请选择" size="small" style="width: 100%;">
          <el-option :label="1" :value="1"></el-option>
          <el-option :label="2" :value="2"></el-option>
          <el-option :label="3" :value="3"></el-option>
          <el-option :label="4" :value="4"></el-option>
        </el-select>
      </field-item>
      <field-item label="拍照示范图">
        <van-uploader
            v-model="form.photoExmpleUrlList"
            :after-read="handleReadImg2"
            :preview-size="70"
            :upload-icon="require('@/assets/svg/icon-upload-add.svg')"
            class="image-uploader"
        >
          <van-button color="#055C9D" plain class="upload-btn">上传图片</van-button>
        </van-uploader>
      </field-item>
      <field-item label="拍照要求描述">
        <el-input v-model="form.photoDescription" placeholder="请输入" type="textarea" :rows="3" @blur="handleInputBlur"/>
      </field-item>
    </div>
    <div class="btn-wrapper space-between">
      <van-button class="btn" color="#055C9D" size="large" plain @click="handleCancel">取消</van-button>
      <van-button class="btn" color="#055C9D" size="large" :loading="loading" type="primary" @click="handleAdd">新增</van-button>
    </div>
  </custom-popup>
</template>

<script>
import CustomPopup from '@/components/Common/CustomPopup.vue'
import FieldItem from "@/components/Common/FieldItem.vue";
import _ from 'lodash'
import {axios} from '@/utils/request';
import {postAction} from '@/api/manage';
import {debounce} from 'lodash'
import { mapGetters } from "vuex";

export default {
  name: 'NewCheckPointPopup',
  components: {FieldItem, CustomPopup},
  props: {
    show: {
      type: Boolean,
      default: false
    },
    singleProductId: {
      type: String,
      default: ''
    },
    productBaseInfoId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      form:{
        checkName: '',
        checkPointName: '',
        checkPointDecription: '',
        imgList: [],
        fileList: [],
        havePhotograghRequire: false,
        photoNumber: null,
        photoDescription: '',
        photoExmpleUrlList: []
      },
      loading: false,
    }
  },
  mounted() {
    this.Toast.clear()
  },
  computed: {
    ...mapGetters(["orderItemDetail"]),
  },
  methods: {
    handleInputBlur(){
      window.scroll(0, 0);
    },
    initForm() {
      this.form = {
        checkName: '',
        checkPointName: '',
        checkPointDecription: '',
        imgList: [],
        fileList: [],
        havePhotograghRequire: false,
        photoNumber: null,
        photoDescription: '',
        photoExmpleUrlList: []
      }
    },
    handleReadImg(info, name) {
      const data = new FormData()
      data.append('file', info.file)
      axios({
        url: 'jeecg-system/platform/common/upload_file',
        method: "post",
        data,
        headers: {
          'Content-Type': 'multipart/form-data',  // 文件上传
        }
      }).then(res => {
        if (res.success) {
          info.url = res.message
          info.content = res.message
        } else {
          // 上传失败后，删除该图片
          let index = this.form.imgList.findIndex(item => item.content === info.content)
          if(index > -1){
            this.form.imgList.splice(index, 1)
          }
          this.Toast.fail(res.message || "上传图片失败!")
        }
      }).catch(error => {
        // 上传失败后，删除该图片
        let index = this.form.imgList.findIndex(item => item.content === info.content)
        if(index > -1){
          this.form.imgList.splice(index, 1)
        }
        this.Toast.fail("上传图片失败")
      })
    },
    handleReadImg2(info, name){
      const data = new FormData()
      data.append('file', info.file)
      axios({
        url: 'jeecg-system/platform/common/upload_file',
        method: "post",
        data,
        headers: {
          'Content-Type': 'multipart/form-data',  // 文件上传
        }
      }).then(res => {
        if (res.success) {
          info.url = res.message
          info.content = res.message
        } else {
          // 上传失败后，删除该图片
          let index = this.form.photoExmpleUrlList.findIndex(item => item.content === info.content)
          if(index > -1){
            this.form.photoExmpleUrlList.splice(index, 1)
          }
          this.Toast.fail(res.message || "上传图片失败!")
        }
      }).catch(error => {
        // 上传失败后，删除该图片
        let index = this.form.photoExmpleUrlList.findIndex(item => item.content === info.content)
        if(index > -1){
          this.form.photoExmpleUrlList.splice(index, 1)
        }
        this.Toast.fail( "上传图片失败")
      })
    },
    handleReadFile(info) {
      info.status = 'loading'
      let data = new FormData()
      data.append('file', info.file)
      this.form.fileList.push(info)
      axios({
        url: 'jeecg-system/platform/common/upload_file',
        method: "post",
        data,
        headers: {
          'Content-Type': 'multipart/form-data',  // 文件上传
        }
      }).then(res => {
        if(res.success){
          info.status = 'success'
          info.url = res.message
          info.content = res.message
        }else{
          // 上传失败后，删除该文件
          let index = this.form.fileList.findIndex(item => item.content === info.content)
          if(index > -1){
            this.form.fileList.splice(index, 1)
          }
          this.Toast.fail(res.message || "上传文件失败!")
        }
      }).catch(error => {
        // 上传失败后，删除该文件
        let index = this.form.fileList.findIndex(item => item.content === info.content)
        if(index > -1){
          this.form.fileList.splice(index, 1)
        }
        this.Toast.fail( "上传文件失败")
      })
    },
    handleFileDel(index) {
      this.form.fileList.splice(index, 1)
    },
    handleCancel() {
      this.initForm()
      this.$emit('on-close')
    },
    handleAdd: debounce(async function(){
      if (!this.form.checkName) {
        this.$toast('请输入检查项名称');
        return
      } else if (!this.form.checkPointName) {
        this.Toast('请输入检查点名称');
        return
      }else if (!this.form.checkPointDecription){
        this.Toast('请输入检查点文字描述');
        return
      } else if (this.form.havePhotograghRequire) {
        if (!this.form.photoNumber) {
          this.Toast('请选择拍照数量');
          return
        } else if (!/^[0-9]*[1-9][0-9]*$/.test(this.form.photoNumber)) {
          this.Toast('拍照数量必须正整数');
          return
        }
      }
      if(this.form.fileList.find(item => !item.url)){
        this.Toast('请等待文件上传完成！');
        return
      }
      if(this.form.imgList.find(item => !item.url)){
        this.Toast('请等待图片上传完成！');
        return
      }
      if(this.form.photoExmpleUrlList.find(item => !item.url)){
        this.Toast('请等待拍照示范图上传完成！');
        return
      }
      this.loading = true
      let form = _.cloneDeep(this.form)
      let data = {
        ...this.form,
        havePhotograghRequire: this.form.havePhotograghRequire ? 'Y' : 'N',
        "checkpointType": "CUSTOM_CHECKPOINT",
        "fileUrl": this.form.fileList.map(item => item.url).join(','),
        "imgUrl": this.form.imgList.map(item => item.url).join(','),
        photoExmpleUrl: this.form.photoExmpleUrlList.map(item => item.url).join(','),
        "productBaseInfoId": this.productBaseInfoId,
        "singleProductId": this.singleProductId,
        'taskOrderItemId':this.orderItemDetail.id
      }
      data.checkPointName=data.checkPointName+'（自定义检查点）'
      delete data.fileList
      delete data.imgList
      delete data.photoExmpleUrlList
      const res = await postAction('/jeecg-system/admin/singleProductCheck/add', data)
      this.loading = false
      if(res.success){
        this.initForm()
        this.$emit('on-ok', form)
      }else{
        this.Toast.fail(res.message)
      }
    },500),
  }
}
</script>

<style scoped lang="less">
.new-check-point::v-deep {
  .content{
    margin-right: -20px;
    padding-right: 20px;
    max-height: calc(85vh - 132px);
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
  }
  .btn-wrapper {
    margin-top: 22px;

    .btn {
      width: 163px;
    }
  }

  .upload-tip {
    font-size: 12px;
    color: #4B4E51;
    padding-right: 32px;
    margin-bottom: 10px;
  }
  .uploader-file-img-box {
    width: 100%;
  }

  .upload-btn {
    height: 36px;
  }
  .image-uploader {
    .van-uploader__input-wrapper {
      width: 100%;
    }
  }

  .file-box {
    width: 100%;

    .item {
      width: 100%;
      margin-bottom: 8px;

      .file-name {
        width: calc(100% - 72px - 17px);
        padding-right: 17px;
        height: 32px;
        background: rgba(255, 255, 255, 0.90);
        border: 1px solid #DCDCDC;
        border-radius: 3px;
        display: inline-flex;
        align-items: center;

        img {
          width: 16px;
          height: 16px;
          margin: 0 8px;
        }

        .name {
          display: inline-block;
          width: calc(100% - 16px - 16px);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .del-btn {
        width: 60px;
        height: 32px;
        background: rgba(255, 255, 255, 0.90);
        border: 1px solid #DCDCDC;
        border-radius: 3px;
        margin-left: 12px;
        flex-shrink: 0;
      }
    }
  }
}
</style>
