<template>
  <van-popup
      :value="show"
      class="custom-popup"
      position="bottom"
      round
      closeable
      get-container="body"
      :close-icon="require('@/assets/svg/icon-close.svg')"
      @click-overlay="$emit('on-close')"
      @click-close-icon="$emit('on-close')"
  >
    <div v-if="title" class="title">{{ title }}</div>
    <slot/>
  </van-popup>
</template>

<script>
export default {
  name: 'CustomPopup',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
}
</script>

<style scoped lang="less">
.custom-popup::v-deep {
  padding: 16px 16px 10px;
  box-sizing: border-box;
  overflow-x: hidden;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #4B4E51;
    line-height: 18px;
    margin-bottom: 20px;
  }

  .van-popup__close-icon {
    font-size: 14px;
  }

  .pop-content{
    min-height: 30vh;
    max-height: 55vh;
    overflow-y: auto;
    -webkit-overflow-scrolling:touch;
    margin-right: -20px;
    padding-right: 20px;
  }
}
</style>
