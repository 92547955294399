<template>
  <div class="field-item space-between">
    <div class="field-label" :class="{'required': required}">{{ label }}</div>
    <div class="field-value space-between">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FieldItem",
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="less">
.field-item {
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  .field-label {
    width: 75px;
    padding-right: 5px;
    line-height: 22px;
    font-size: 12px;
    color: #AAB3BA;
    word-break: break-all;
    position: relative;

    &.required::after {
      content: "*";
      color: #F75658;
      margin-left: 2px;
      position: absolute;
    }
  }

  .field-value {
    width: calc(100% - 80px);
    font-size: 14px;
    line-height: 22px;
    color: #4B4E51;
  }
}
</style>
